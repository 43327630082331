var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base",
      attrs: { title: "Horizontal Bar Chart", "code-toggler": "" },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("chartjs-component-horizontal-bar-chart", {
            attrs: { height: 250, data: _vm.data, options: _vm.options },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<script>\nimport { HorizontalBar } from \'vue-chartjs\'\n\nexport default {\n  extends: Line,\n  data() {\n    return {\n      data: {\n        labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],\n        datasets: [{\n          label: "Population (millions)",\n          backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],\n          data: [2478, 5267, 734, 784, 433]\n        }]\n      },\n      options: {\n        legend: { display: false },\n        title: {\n          display: true,\n          text: \'Predicted world population (millions) in 2050\'\n        }\n      }\n    }\n  }\n  mounted () {\n    this.renderChart(this.data, this.options)\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }