var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base",
      attrs: { title: "Bubble Chart", "code-toggler": "" },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("chartjs-component-bubble-chart", {
            attrs: { height: 250, data: _vm.data, options: _vm.options },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<script>\nimport { Bubble } from \'vue-chartjs\'\n\nexport default {\n  extends: Line,\n  data() {\n    return {\n      data: {\n        labels: "Africa",\n        datasets: [{\n          label: ["China"],\n          backgroundColor: "rgba(255,221,50,0.2)",\n          borderColor: "rgba(255,221,50,1)",\n          data: [{\n            x: 21269017,\n            y: 5.245,\n            r: 15\n          }]\n        }, {\n          label: ["Denmark"],\n          backgroundColor: "rgba(60,186,159,0.2)",\n          borderColor: "rgba(60,186,159,1)",\n          data: [{\n            x: 258702,\n            y: 7.526,\n            r: 10\n          }]\n        }, {\n          label: ["Germany"],\n          backgroundColor: "rgba(0,0,0,0.2)",\n          borderColor: "#000",\n          data: [{\n            x: 3979083,\n            y: 6.994,\n            r: 15\n          }]\n        }, {\n          label: ["Japan"],\n          backgroundColor: "rgba(193,46,12,0.2)",\n          borderColor: "rgba(193,46,12,1)",\n          data: [{\n            x: 4931877,\n            y: 5.921,\n            r: 15\n          }]\n        }]\n      },\n      options: {\n        title: {\n          display: true,\n          text: \'Predicted world population (millions) in 2050\'\n        },\n        scales: {\n          yAxes: [{\n            scaleLabel: {\n              display: true,\n              labelString: "Happiness"\n            }\n          }],\n          xAxes: [{\n            scaleLabel: {\n              display: true,\n              labelString: "GDP (PPP)"\n            }\n          }]\n        }\n      }\n    }\n  }\n  mounted () {\n    this.renderChart(this.data, this.options)\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }