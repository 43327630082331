var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base",
      attrs: { title: "Radar Chart", "code-toggler": "" },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("chartjs-component-radar-chart", {
            attrs: { height: 250, data: _vm.data, options: _vm.options },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<script>\nimport { Radar } from \'vue-chartjs\'\n\nexport default {\n  extends: Line,\n  data() {\n    return {\n      data: {\n        labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],\n        datasets: [{\n          label: "1950",\n          fill: true,\n          backgroundColor: "rgba(179,181,198,0.2)",\n          borderColor: "rgba(179,181,198,1)",\n          pointBorderColor: "#fff",\n          pointBackgroundColor: "rgba(179,181,198,1)",\n          data: [8.77, 55.61, 21.69, 6.62, 6.82]\n        }, {\n          label: "2050",\n          fill: true,\n          backgroundColor: "rgba(255,99,132,0.2)",\n          borderColor: "rgba(255,99,132,1)",\n          pointBorderColor: "#fff",\n          pointBackgroundColor: "rgba(255,99,132,1)",\n          pointBorderColor: "#fff",\n          data: [25.48, 54.16, 7.61, 8.06, 4.45]\n        }]\n      },\n      options: {\n        title: {\n          display: true,\n          text: \'Distribution in % of world population\'\n        }\n      }\n    }\n  }\n  mounted () {\n    this.renderChart(this.data, this.options)\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }