var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base",
      attrs: { title: "Line Chart", "code-toggler": "" },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("chartjs-component-line-chart", {
            attrs: { height: 250, data: _vm.data, options: _vm.options },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<script>\nimport { Line } from \'vue-chartjs\'\n\nexport default {\n  extends: Line,\n  data() {\n    return {\n      data: {\n        labels: [1500, 1600, 1700, 1750, 1800, 1850, 1900, 1950, 1999, 2050],\n        datasets: [{\n          data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],\n          label: "Africa",\n          borderColor: "#3e95cd",\n          fill: false\n        }, {\n          data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],\n          label: "Asia",\n          borderColor: "#8e5ea2",\n          fill: false\n        }, {\n          data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],\n          label: "Europe",\n          borderColor: "#3cba9f",\n          fill: false\n        }, {\n          data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],\n          label: "Latin America",\n          borderColor: "#e8c3b9",\n          fill: false\n        }, {\n          data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],\n          label: "North America",\n          borderColor: "#c45850",\n          fill: false\n        }]\n      },\n      options: {\n        title: {\n          display: true,\n          text: \'World population per region (in millions)\'\n        }\n      }\n    }\n  },\n  mounted () {\n    this.renderChart(this.data, this.options)\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }